export const Z_INDICES = {
  winOverlay: 90,
  dropdown: 100
};

export const COLORS = {
  appBackground: "#111",
  appText: "#aaa",
  appTextActive: "#fff",
  dropdownActiveBackground: "#111",
  dropdownActiveText: "#fff"
};
