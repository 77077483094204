// import chroma from "chroma-js";
// import sortBy from "lodash/sortBy";
export const niceColors = [
  "#cd5c5c",
  "#bc8f8f",
  "#e31f16",
  "#be0e01",
  "#ffe4e1",
  "#fa8072",
  "#df5f4b",
  "#fbc8c0",
  "#f09a8b",
  "#fb8960",
  "#e0beaf",
  "#fb6800",
  "#f4a460",
  "#eb9348",
  "#ffe4c4",
  "#ff8c00",
  "#deb887",
  "#d2b48c",
  "#f3d7a7",
  "#ffe4b5",
  "#faeac8",
  "#ffc33f",
  "#ecbe48",
  "#e9f88f",
  "#a4d273",
  "#3b9120",
  "#26d01b",
  "#90ee90",
  "#3b3e3b",
  "#aeedb7",
  "#05681d",
  "#044715",
  "#2e8b57",
  "#598b73",
  "#5db89c",
  "#3f9489",
  "#b6e3df",
  "#729f9d",
  "#008080",
  "#2f4f4f",
  "#5f9ea0",
  "#90d4d8",
  "#67d3ea",
  "#006c8f",
  "#006c8f",
  "#00bfff",
  "#456775",
  "#4e96bf",
  "#6a91ab",
  "#1e90ff",
  "#cdc7f8",
  "#483d8b",
  "#d8bfd8",
  "#d280bc",
  "#de70ba",
  "#cb3b6f",
  "#58323d",
  "#e74d71",
  "#dc143c",
  "#f39aaa",
  "#ffc0cb",
  "#dcdcdc",
  "#c0c0c0",
  "#808080"
];

// console.log(
//   JSON.stringify(sortBy(niceColors, hex => chroma(hex).get("hsl.h")))
// );
